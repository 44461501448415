:root {
  --epr-emoji-size: 1.5rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  @apply font-source-sans-pro;
}

body {
  @apply bg-black-800 text-white;
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  --scroll-padding-top: 64px !important;
  scroll-padding-top: 64px !important;
}

button, input, optgroup, select, textarea {
  @apply font-source-sans-pro;
}

::selection {
  background:#0076d1 ;
  color: #FFFFFF;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

::-webkit-scrollbar{
  width: 0.5rem;
  height: 0.5rem;
}

.scrollbar-trigger ::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.scrollbar-trigger:hover ::-webkit-scrollbar-thumb {
  visibility: visible;
}

.scrollbar-small-content ::-webkit-scrollbar-thumb {
  visibility: visible;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 4px;
  min-height: 40px;

  @apply bg-black-500;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-black-600;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;

  @apply bg-black-800;
}

#carousel-swiper .swiper-slide {
  width: var(--carousel-slider-width);
  height: var(--carousel-slider-height);
  position: relative;
}

#carousel-vertical-swiper .swiper-slide {
  filter: blur(5px);
  width: var(--carousel-vertical-slider-width);
  height: var(--carousel-vertical-slider-height);
  position: relative;
}

#carousel-vertical-swiper .swiper-slide-active {
  filter: blur(0px);
}

.masonry-grid {
  @apply flex gap-4 w-full
}

.masonry-column {
  background-clip: padding-box;
}

.masonry-item {
  display: inline-block;
  width: 100%;
  max-width: 500px;
}

.games-carousel {
  @apply w-full
}

.games-carousel .splide__track {
  @apply overflow-hidden;
}

.games-carousel .splide__slide.is-visible {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.games-carousel .splide__slide:not(.is-visible) {
  opacity: 0.2;
}

@media (max-width: 640px) {
  .games-carousel .splide__slide:not(.is-visible),
  .games-carousel .splide__slide.is-next,
  .games-carousel .splide__slide.is-prev {
    opacity: 0.2;
  }
}

.games-carousel .splide__arrow--prev,
.games-carousel .splide__arrow--next {
  @apply hidden
}

.home-splide {
  @apply w-full
}

.home-splide .splide__track {
  @apply overflow-hidden;
}

.home-splide .splide__slide.is-visible {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.home-splide .splide__slide:not(.is-visible) {
  opacity: 0.2;
}

.home-splide .splide__arrow--prev,
.home-splide .splide__arrow--next {
  @apply top-1/2 -translate-y-1/2;
}

.home-splide .splide__arrow--prev {
  @apply -left-10;
}
.home-splide .splide__arrow--next {
  @apply -right-10;
}

.apg-picker-dropdown {
  @apply z-[90]
}

.apg-post-editor-wrapper a,
.apg-reply-box p a,
.apg-comment-box p a {
  @apply text-aqua no-underline
}

#typeahead-menu {
  @apply z-[90]
}

.apg-post-content b,
.apg-post-content strong {
  @apply font-bold text-white
}

.apg-post-content em {
  @apply italic text-white
}

.apg-post-content a {
  @apply text-aqua no-underline
}

.characters-limit {
  @apply text-xs text-gray-500 absolute bottom-0 right-0
}

.characters-limit-exceeded {
  @apply inline text-xs text-red-500
}

.apg_editor_theme__characterLimit {
  @apply bg-red-800 inline
}

.comment-wrapper > p {
  @apply !inline
}